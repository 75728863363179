import { instanceAxios } from "../../services/axiosServices";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getAuthSkinConfigFailed,
  getAuthSkinConfigRequested,
  getAuthSkinConfigSucceded,
  getSkinConfigFailed,
  getSkinConfigRequested,
  getSkinConfigSucceded,
} from "./configSlice";

export function* getSkin(): unknown {
  try {
    const response = yield call(instanceAxios.get, "/api/skin/settings");
    const { data } = response.data;

    yield put(getSkinConfigSucceded(data));
    // yield put(saveBettingConfigSucceded(data.betting));
  } catch (error) {
    yield put(getSkinConfigFailed());
  }
}

export function* getAuthSkin({
  payload,
}: ReturnType<typeof getAuthSkinConfigRequested>): unknown {
  try {
    const response = yield yield call(
      instanceAxios.get,
      `/api/wallet/settings/${payload}`
    );
    const { data } = response.data;
    // yield put(saveBettingConfigSucceded(data.betting));
    yield put(getAuthSkinConfigSucceded(data));
  } catch (error) {
    yield put(getAuthSkinConfigFailed());
  }
}

export function* ongetSkinConfigRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(getSkinConfigRequested, getSkin);
}
export function* ongetAuthSkinConfigRequested(): Generator<
  unknown,
  void,
  unknown
> {
  yield takeLatest(getAuthSkinConfigRequested, getAuthSkin);
}

export function* configSagas(): unknown {
  yield all([
    call(ongetSkinConfigRequested),
    call(ongetAuthSkinConfigRequested),
  ]);
}
