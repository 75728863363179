import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./theme";

import Content from './components/shared/Content';

import Modal from './components/shared/Modal';
import PlayedCouponReceiptDialog from './components/coupon/PlayedCouponReceiptDialog';
import './App.scss';

import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import CouponMobile from './components/shared/CouponMobile';
import Toast from './components/shared/Toast';
import { SPORT_SKIN_CONFIG_INTERVAL_TIMING } from './constants';
import { getAuthSkinConfigRequested, getSkinConfigRequested } from './redux/config/configSlice';

const cache = createCache({
  key: 'css',
  prepend: true,
});


function App() {
  const dispatch = useAppDispatch()
  const selectedEvent = useAppSelector((state) => state.live.selectedEvent);
  const defaultFirstEvent = useAppSelector((state) => state.live.defaultFirstEvent);
  const userAuth = useAppSelector((state) => state.auth.isAuth);
  const bettingWalletId = useAppSelector((state) => state.wallet.bettingWalletId);
  const locale = useAppSelector((state) => state.auth.locale);

  useEffect(() => {
    if (userAuth && bettingWalletId) {
      dispatch(getAuthSkinConfigRequested(bettingWalletId))
      const interval = setInterval(() => {
        dispatch(getAuthSkinConfigRequested(bettingWalletId));
      }, SPORT_SKIN_CONFIG_INTERVAL_TIMING);
      return () => {
        clearInterval(interval)
      }
    } else if (!userAuth && !bettingWalletId) {
      dispatch(getSkinConfigRequested());
      const interval = setInterval(() => {
        dispatch(getSkinConfigRequested());
      }, SPORT_SKIN_CONFIG_INTERVAL_TIMING);
      return () => {
        clearInterval(interval)
      }
    }
  }, [userAuth, bettingWalletId]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://livetracker.live/widgets/${locale}`;

    script.addEventListener("load", () => {
      if (selectedEvent && window.SBWidget) {
        window.SBWidget.ctm();
        window.SBWidget.ltm(selectedEvent, ".widget-here");
      } else if (defaultFirstEvent && window.SBWidget) {
        window.SBWidget.ctm();
        window.SBWidget.ltm(defaultFirstEvent, ".widget-here");
      }
    });
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [locale]);

  return (
    <CacheProvider value={cache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Content />
        <Modal />
        <CouponMobile />
        <PlayedCouponReceiptDialog />
        <Toast />
      </ThemeProvider>
    </CacheProvider>
  );
}

export default App;
